import Moment from 'moment'
import {
	mapGetters
} from 'vuex'
import {uuid} from 'vue-uuid'
export default {
	data() {
		return {
			queryForm: {
				websites: [],
				date: [
					Moment().add(-7, 'days').format('yyyy-MM-DD'),
					Moment().add(-1, 'days').format('yyyy-MM-DD')
				],
				repeatDays: null,
				siteCross: null
			},
			q: {},
			tableData: [],
			fetchOnCreated: false,
			orderBy: 'website',
			orderDirection: 'asc'
		}
	},
	computed: {
		...mapGetters({
			tipInfo: 'repurchase/tipInfo',
			repeatDays: 'repurchase/repeatDays',
			siteCross: 'repurchase/siteCross',
			orderByEnums: 'repurchase/orderBy',
		})
	},
	created() {
		const {start, end, repeatDays, siteCross, orderBy} = this.$route.query
		if (!!start && !!end && /^\d{4}-\d{2}-\d{2}$/.test(start) && /^\d{4}-\d{2}-\d{2}$/.test(end) && Moment(start).diff(Moment(end), 'days') <= 0) {
			this.queryForm.date = [start, end]
		}
		if (repeatDays && this.repeatDays.find(v => v.key === repeatDays) !== undefined) {
			this.queryForm.repeatDays = repeatDays
		} else {
			this.queryForm.repeatDays = this.repeatDays[1].key
		}
		if (siteCross && this.siteCross.find(v => v.key === siteCross) !== undefined) {
			this.queryForm.siteCross = siteCross
		} else {
			this.queryForm.siteCross = this.siteCross[0].key
		}
		if (orderBy && this.orderByEnums.find(v => orderBy === v) !== undefined) {
			const val = orderBy.split(',')
			this.orderBy = val[0]
			this.orderDirection = val[1]
		}
		this.searchEvent()
	},
	methods: {
		searchEvent() {
			this.q = {
				createdMinStr: this.queryForm.date[0],
				createdMaxStr: this.queryForm.date[1],
				websites: this.queryForm.websites,
				repeatDays: this.queryForm.repeatDays,
				siteCross: /^true$/.test(this.queryForm.siteCross)
			}
			const orderBy = this.orderBy ? `&orderBy=${this.orderBy},${this.orderDirection}` : ''
			const search = `start=${this.queryForm.date[0]}&end=${this.queryForm.date[1]}&repeatDays=${this.queryForm.repeatDays}&siteCross=${this.queryForm.siteCross}${orderBy}`
			history.replaceState(this.q, '', `${window.location.pathname}?${search}`)
			this.page.current = 1
			this.getList()
		},
		handleRawData(raw) {
			const {data, total} = raw
			this.handlePagable(data)
			const totalList = total.map(v => {
				return {
					...v,
					date: 'TOTAL',
					id: uuid.v4()
				}
			})
			var firstEl = null
			if (totalList.length > 0) {
				firstEl = totalList.shift()
				firstEl.children = totalList
			}
			const tableData = data.content.map(v => {
				return {
					...v,
					id: uuid.v4(),
					date: Moment(Number(v.date) * 1000).format('YYYY-MM-DD')
				}
			})
			this.handleList([firstEl, ...tableData])
		},
		handleList(content) {
			this.tableData = content
		}
	}
}