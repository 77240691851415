<template>
	<main-container :showHeader="false">
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item label="选择日期:" style="width:300px;">
						<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:340px;" size="mini" :clearable="false">
						</el-date-picker>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">搜 索</el-button>
						<el-button type="primary" size="mini" @click="exportAction">导 出</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item-sites :abbr="true" v-model="queryForm.websites" :parent="true" :clearable="false"></form-item-sites>
					<form-item label="时间口径" :tips="tipInfo.repeatDays" :tipWidth="360">
						<el-select v-model="queryForm.repeatDays" :clearable="false" size="mini">
							<el-option v-for="item in repeatDays" :label="item.label" :value="item.key" :key="item.key"></el-option>
						</el-select>
					</form-item>
					<form-item label="站点口径" :tips="tipInfo.siteCross">
						<el-select v-model="queryForm.siteCross" :clearable="false" size="mini">
							<el-option v-for="item in siteCross" :label="item.label" :value="item.key" :key="item.key"></el-option>
						</el-select>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<el-table height="auto" :data="tableData" size="mini" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="id" :row-class-name="rowClassName" @sort-change="sortChange" :default-sort ="defaultSort">
				<el-table-column label="日期" prop="date" min-width="120" align="center">
				</el-table-column>
				<el-table-column label="站点" prop="website" min-width="120" align="center" sortable="custom">
				</el-table-column>
				<el-table-column label="订单数" prop="orderCount" min-width="120" align="center" :formatter="formatterInteger" sortable="custom">
				</el-table-column>
				<el-table-column label="GMV" prop="gmv" min-width="120" align="center" :formatter="formatterDecimal" sortable="custom">
				</el-table-column>
				<el-table-column label="客单价" prop="atv" min-width="120" align="center" :formatter="formatterDecimal">
				</el-table-column>
				<el-table-column prop="userCount" min-width="120" align="center" :formatter="formatterInteger">
					<template slot="header">
						<header-with-tip :infos="tipInfo.users" title="用户数"></header-with-tip>
					</template>
				</el-table-column>
				<el-table-column label="复购订单数" prop="repeatOrderCount" min-width="120" align="center" :formatter="formatterInteger">
				</el-table-column>
				<el-table-column label="复购GMV" prop="repeatGmv" min-width="120" align="center" :formatter="formatterDecimal">
				</el-table-column>
				<el-table-column label="复购GMV占总GMV比例" prop="repeatRate" min-width="100" align="center" :formatter="formatterPercent">
					<template slot="header">
						<header-with-tip :infos="tipInfo.repurchaseGmvPercent" title="复购GMV占总GMV比例"></header-with-tip>
					</template>
				</el-table-column>
				<el-table-column label="复购客单价" prop="repeatAtv" min-width="120" align="center" :formatter="formatterDecimal">
				</el-table-column>
				<el-table-column label="复购用户数" prop="repeatUserCount" min-width="100" align="center" :formatter="formatterInteger">
				</el-table-column>
				<el-table-column label="复购率" prop="userRate" min-width="100" align="center" :formatter="formatterPercent">
					<template slot="header">
						<header-with-tip :infos="tipInfo.repurchaseRate" title="复购率"></header-with-tip>
					</template>
				</el-table-column>
				<el-table-column label="渠道复购贡献度" align="center">
					<el-table-column label="Facebook" align="center">
						<el-table-column label="复购订单数" prop="fbRepeatOrderCount" min-width="120" align="center" :formatter="formatterInteger">
						</el-table-column>
						<el-table-column label="复购GMV" prop="fbRepeatGmv" min-width="120" align="center" :formatter="formatterDecimal">
						</el-table-column>
						<el-table-column label="复购GMV占总GMV比例" prop="fbRepeatRate" min-width="100" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchaseGmvPercent" title="复购GMV占总GMV比例"></header-with-tip>
							</template>
						</el-table-column>
						<el-table-column label="复购贡献度" prop="fbRepeatContribution" min-width="120" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchasePercent" title="复购贡献度"></header-with-tip>
							</template>
						</el-table-column>
					</el-table-column>
					<el-table-column label="Google" align="center">
						<el-table-column label="复购订单数" prop="ggRepeatOrderCount" min-width="120" align="center" :formatter="formatterInteger">
						</el-table-column>
						<el-table-column label="复购GMV" prop="ggRepeatGmv" min-width="120" align="center" :formatter="formatterDecimal">
						</el-table-column>
						<el-table-column label="复购GMV占总GMV比例" prop="ggRepeatRate" min-width="100" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchaseGmvPercent" title="复购GMV占总GMV比例"></header-with-tip>
							</template>
						</el-table-column>
						<el-table-column label="复购贡献度" prop="ggRepeatContribution" min-width="120" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchasePercent" title="复购贡献度"></header-with-tip>
							</template>
						</el-table-column>
					</el-table-column>
					<el-table-column label="Tiktok" align="center">
						<el-table-column label="复购订单数" prop="ttRepeatOrderCount" min-width="120" align="center" :formatter="formatterInteger">
						</el-table-column>
						<el-table-column label="复购GMV" prop="ttRepeatGmv" min-width="120" align="center" :formatter="formatterDecimal">
						</el-table-column>
						<el-table-column label="复购GMV占总GMV比例" prop="ttRepeatRate" min-width="100" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchaseGmvPercent" title="复购GMV占总GMV比例"></header-with-tip>
							</template>
						</el-table-column>
						<el-table-column label="复购贡献度" prop="ttRepeatContribution" min-width="120" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchasePercent" title="复购贡献度"></header-with-tip>
							</template>
						</el-table-column>
					</el-table-column>
					<el-table-column label="AFF" align="center">
						<el-table-column label="复购订单数" prop="affRepeatOrderCount" min-width="120" align="center" :formatter="formatterInteger">
						</el-table-column>
						<el-table-column label="复购GMV" prop="affRepeatGmv" min-width="120" align="center" :formatter="formatterDecimal">
						</el-table-column>
						<el-table-column label="复购GMV占总GMV比例" prop="affRepeatRate" min-width="100" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchaseGmvPercent" title="复购GMV占总GMV比例"></header-with-tip>
							</template>
						</el-table-column>
						<el-table-column label="复购贡献度" prop="affRepeatContribution" min-width="120" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchasePercent" title="复购贡献度"></header-with-tip>
							</template>
						</el-table-column>
					</el-table-column>
					<el-table-column label="KOL" align="center">
						<el-table-column label="复购订单数" prop="kolRepeatOrderCount" min-width="120" align="center" :formatter="formatterInteger">
						</el-table-column>
						<el-table-column label="复购GMV" prop="kolRepeatGmv" min-width="120" align="center" :formatter="formatterDecimal">
						</el-table-column>
						<el-table-column label="复购GMV占总GMV比例" prop="kolRepeatRate" min-width="100" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchaseGmvPercent" title="复购GMV占总GMV比例"></header-with-tip>
							</template>
						</el-table-column>
						<el-table-column label="复购贡献度" prop="kolRepeatContribution" min-width="120" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchasePercent" title="复购贡献度"></header-with-tip>
							</template>
						</el-table-column>
					</el-table-column>
					<el-table-column label="EDM" align="center">
						<el-table-column label="复购订单数" prop="edmRepeatOrderCount" min-width="120" align="center" :formatter="formatterInteger">
						</el-table-column>
						<el-table-column label="复购GMV" prop="edmRepeatGmv" min-width="120" align="center" :formatter="formatterDecimal">
						</el-table-column>
						<el-table-column label="复购GMV占总GMV比例" prop="edmRepeatRate" min-width="100" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchaseGmvPercent" title="复购GMV占总GMV比例"></header-with-tip>
							</template>
						</el-table-column>
						<el-table-column label="复购贡献度" prop="edmRepeatContribution" min-width="120" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchasePercent" title="复购贡献度"></header-with-tip>
							</template>
						</el-table-column>
					</el-table-column>
					<el-table-column label="SMS" align="center">
						<el-table-column label="复购订单数" prop="smsRepeatOrderCount" min-width="120" align="center" :formatter="formatterInteger">
						</el-table-column>
						<el-table-column label="复购GMV" prop="smsRepeatGmv" min-width="120" align="center" :formatter="formatterDecimal">
						</el-table-column>
						<el-table-column label="复购GMV占总GMV比例" prop="smsRepeatRate" min-width="100" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchaseGmvPercent" title="复购GMV占总GMV比例"></header-with-tip>
							</template>
						</el-table-column>
						<el-table-column label="复购贡献度" prop="smsRepeatContribution" min-width="120" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchasePercent" title="复购贡献度"></header-with-tip>
							</template>
						</el-table-column>
					</el-table-column>
					<el-table-column label="Others" align="center">
						<el-table-column label="复购订单数" prop="othersRepeatOrderCount" min-width="120" align="center" :formatter="formatterInteger">
						</el-table-column>
						<el-table-column label="复购GMV" prop="othersRepeatGmv" min-width="120" align="center" :formatter="formatterDecimal">
						</el-table-column>
						<el-table-column label="复购GMV占总GMV比例" prop="othersRepeatRate" min-width="100" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchaseGmvPercent" title="复购GMV占总GMV比例"></header-with-tip>
							</template>
						</el-table-column>
						<el-table-column label="复购贡献度" prop="othersRepeatContribution" min-width="120" align="center" :formatter="formatterPercent">
							<template slot="header">
								<header-with-tip :infos="tipInfo.repurchasePercent" title="复购贡献度"></header-with-tip>
							</template>
						</el-table-column>
					</el-table-column>
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
	</main-container>
</template>

<script>
	import Moment from 'moment'
	import Page from '@/common/mixins/page.js'
	import Common from '@/common/mixins/common.js'
	import Config from './mixins/config.js'
	import Lodash from 'lodash'
	import HeaderWithTip from '@/components/carousels/header-with-tip.vue'
	export default {
		mixins: [Page, Common, Config],
		inject: ['adminLayout'],
		components: {
			HeaderWithTip
		},
		data() {
			return {
				namespace: 'repurchase'
			}
		},
		computed: {
			defaultSort() {
				return {
					prop: this.orderBy, 
					order: this.orderDirection === 'desc' ? 'descending' : 'ascending',
				}
			}
		},
		methods: {
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const websites = this.q.websites.length > 0 ? this.q.websites : ['ALL']
				const payload = {
					params: {
						...this.page,
						...this.q,
						websites,
						orderBy: this.orderBy,
						orderDirection: this.orderDirection
					},
					trigger
				}
				return this.$store.dispatch(`${this.namespace}/getList`, payload)
			},
			formatterInteger(row, column, cellValue) {
				const val = +cellValue
				return isNaN(val) ? 0 : val
			},
			formatterDecimal(row, column, cellValue) {
				const val = +cellValue
				return isNaN(val) ? '0.00' : val.toFixed(2)
			},
			formatterPercent(row, column, cellValue) {
				const val = +cellValue * 100
				return isNaN(val) ? '0.00%' : `${val.toFixed(2)}%`
			},
			rowClassName({row, rowIndex}) {
				const {date} = row
				return date && /^total$/i.test(date) ? 'summary-row el-table__row--level-0' : ''
			},
			exportAction() {
				const websites = this.q.websites.length > 0 ? this.q.websites : ['ALL']
				const payload = {
					...this.q,
					websites,
					orderBy: this.orderBy,
					orderDirection: this.orderDirection
				}
				this.$store.commit('download-manager/addTask', {
					action: `${this.namespace}/export`,
					payload,
					title: `REPEAT(${this.q.createdMinStr}~${this.q.createdMaxStr})`,
					dispatch: this.$store.dispatch,
					responseType: 'blob'
				})
			},
			// Table Events
			sortChange(column) {
				const {prop, order} = column
				if (order === null) {
					this.orderBy = null
					this.orderDirection = null
				} else {
					this.orderBy = prop
					this.orderDirection = order === 'descending' ? 'desc' : 'asc'
				}
				this.searchEvent()
			}
		}
	}
</script>

<style lang="scss">
	.summary-row {
		background-color: #F5F7FA !important;
	}
	
</style>
